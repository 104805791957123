import { generateSiteDesign } from '@flex-domains/recruiting-site-builder/base';
import type { RecruitingSiteResponse } from '@flexteam/apis-recruiting';
import { PageType } from '@flexteam/apis-recruiting';
import type { GetServerSideProps } from 'next';

import type { WithErrorProps } from '../src';
import {
  fetchRecruitingSite,
  getSubdomainFromServerSidePropsContext,
  handleServerSideError,
  handleSystemSubdomain,
  MainPage,
  useSendAccessLog,
  withErrorPage,
} from '../src';
import { getServerAxios } from '../src/utils/axios';

interface Props {
  recruitingSiteResponse: RecruitingSiteResponse;
}

function Main({ recruitingSiteResponse }: Props) {
  const siteDesign = generateSiteDesign(recruitingSiteResponse);

  useSendAccessLog({
    customerIdHash: siteDesign.customerIdHash,
    pageType: PageType.Site,
  });

  return <MainPage siteDesign={siteDesign} />;
}

const MainWithErrorPage = withErrorPage(Main);
export default MainWithErrorPage;

type ServerSideProps = WithErrorProps<Props>;
export const getServerSideProps: GetServerSideProps<
  ServerSideProps
> = async context => {
  try {
    const axiosInstance = getServerAxios({
      req: context.req,
      res: context.res,
    });

    const subdomain = getSubdomainFromServerSidePropsContext(context);

    await handleSystemSubdomain({ context, axiosInstance, subdomain });

    const recruitingSiteResponse = await fetchRecruitingSite(axiosInstance, {
      subdomain,
    });

    return {
      props: {
        recruitingSiteResponse,
      },
    };
  } catch (error) {
    return handleServerSideError(context, error);
  }
};
